import bg from "../assets/bg.png";
import chef from "../assets/chef.png";
import G from "../assets/G.png";
import gallery01 from "../assets/gallery01.png";
import gallery02 from "../assets/gallery02.png";
import gallery03 from "../assets/gallery03.png";
import gallery04 from "../assets/gallery04.png";
import knife from "../assets/knife.png";
import menu from "../assets/menu.png";
import menu2 from "../assets/menu-2.png";
import overlaybg from "../assets/overlaybg.png";
import spoon from "../assets/spoon.svg";
import welcome from "../assets/welcome.png";
import findus from "../assets/findus.png";
import laurels from "../assets/laurels.png";
import sign from "../assets/sign.png";
import quote from "../assets/quote.png";
import a from "../assets/9.jpg";
import b from "../assets/10.jpg";
import c from "../assets/11.jpg";
import d from "../assets/12.jpg";
import e from "../assets/5.jpg";
import f from "../assets/6.jpg";
import g from "../assets/7.jpg";
import h from "../assets/8.jpg";
import ho1 from "../assets/13.jpg";
import ho2 from "../assets/14.jpg";
import ho3 from "../assets/15.jpg";
import ho4 from "../assets/16.jpg";
import ho5 from "../assets/17.jpg";
import ho6 from "../assets/18.jpg";
import ho7 from "../assets/19.jpg";
import home1 from "../assets/home 1.jpg";
import home2 from "../assets/home 2.jpg";
import home3 from "../assets/home 3.jpg";
import home4 from "../assets/home 4.jpg";
import home5 from "../assets/home 5.jpg";
import home6 from "../assets/home 6.jpg";
import home7 from "../assets/home 7.jpg";
import home8 from "../assets/home 8.jpg";
import home9 from "../assets/home 9.jpg";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  menu,
  menu2,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  sign,
  quote,
  a,
  b,
  c,
  d,
  e,
  f,
  g,
  h,
  ho1,
  ho2,
  ho3,
  ho4,
  ho5,
  ho6,
  ho7,
  home1,
  home2,
  home3,
  home4,
  home5,
  home6,
  home7,
  home8,
  home9,
};
