import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import { AboutUs, FindUs } from "../container";
import Home from "../container/Home/Home";
import Map from "../container/Map/Map";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App></App>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/about",
        element: <AboutUs></AboutUs>,
      },
      {
        path: "/contact",
        element: <FindUs></FindUs>,
      },
      {
        path: "/location",
        element: <Map></Map>,
      },
      // {
      //   path: "/services",
      //   element: <Service></Service>,
      // },
      // {
      //   path: "/products",
      //   element: <Products></Products>,
      // },
    ],
  },
]);

export default router;
