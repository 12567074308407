import images from "./images";

const food = [
  {
    title: "Burger Meal",
    price: "$56",
    tags: "AU | Meal",
  },
  {
    title: "3x Pizza Deal",
    price: "$59",
    tags: "AU | Deal",
  },
  {
    title: "Kebab Meal",
    price: "$44",
    tags: "FR | Deal",
  },
  {
    title: "Shawarma",
    price: "$31",
    tags: "CA | Meal",
  },
  {
    title: "Donner Roll",
    price: "$26",
    tags: "IE | Meal",
  },
];

const drinks = [
  {
    title: "Coca Cola",
    price: "$20",
    tags: "Free with a meal | Soda | 30 ml",
  },
  {
    title: "Pepsi",
    price: "$16",
    tags: "Free with a meal | Soda | 30 ml",
  },
  {
    title: "Water",
    price: "$10",
    tags: "Free with a meal | 30 ml",
  },
  {
    title: "Water",
    price: "$31",
    tags: "Free with a meal | 30 ml",
  },
  {
    title: "Rio",
    price: "$26",
    tags: "Free with a meal | Soda | 30 ml",
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: "Culinary Excellence",
    subtitle:
      "Recognized for maintaining exceptional food safety and hygiene standards while delivering outstanding culinary experiences.",
  },
  {
    imgUrl: images.award01,
    title: "Rising Star 2024",
    subtitle:
      'Awarded "Best New Luxury Lounge" by Elite Dining Guide for innovative cocktails and atmospheric dining.',
  },
  {
    imgUrl: images.award05,
    title: "Diamond Hospitality",
    subtitle:
      "Achieved the prestigious 5-Star rating for exceptional service, ambiance, and consistent guest satisfaction.",
  },
  {
    imgUrl: images.award03,
    title: "Master Mixologist",
    subtitle:
      'Our head mixologist awarded "Craftsman of the Year" for creating signature cocktails that define modern luxury.',
  },
];

export default { food, drinks, awards };
