import React, { useEffect, useRef } from "react";
import { images } from "../../constants";
import "./AboutUs.css";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

const AboutUs = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const scroll = (direction) => {
    const { current } = scrollRef;
    if (direction === "left") {
      current.scrollLeft -= 400;
    } else {
      current.scrollLeft += 400;
    }
  };
  return (
    <div className="relative app__bg   section__padding" id="about">
      <div className="w-full z-2 flex__center flex-col lg:flex-row mt-6">
        <div className=" flex-one flex justify-end items-end flex-col text-right">
          <h1 className="headtext__cormorant">About Us</h1>
          <img src={images.spoon} alt="about_spoon" className="spoon__img" />
          <p className="p__opensans mx-0 my-6 text-primary-gray">
            Step into S.Lounge, where every evening transforms into an
            extraordinary experience. Our carefully curated ambiance combines
            contemporary luxury with timeless elegance, creating the perfect
            setting for memorable moments.
          </p>
          <button type="button" className="custom__button">
            Know More
          </button>
        </div>

        <div className="my-1 mx-2 lg:my-8 lg:mx-16 lg:rotate-0 rotate-90 flex__center">
          <img
            src={images.knife}
            alt="about_knife"
            className="h-80vwh lg:h-screen 2xl:h-70vh"
          />
        </div>

        <div className=" flex-one flex justify-start items-start flex-col text-left">
          <h1 className="headtext__cormorant">Our Legacy</h1>
          <img src={images.spoon} alt="about_spoon" className="spoon__img" />
          <p className="p__opensans mx-0 my-6 text-primary-gray">
            With a heritage of excellence spanning years, S.Lounge has redefined
            luxury entertainment. Our commitment to exceptional service and
            attention to detail has made us the destination for those who seek
            the extraordinary.
          </p>
          <button type="button" className="custom__button">
            Know More
          </button>
        </div>
      </div>

      {/* Photo Gallery Section */}
      <div className="py-16 px-4 sm:px-8 bg-black/50">
        <div className="text-center mb-12">
          <h2 className="headtext__cormorant mb-4">Our Space</h2>
          <img src={images.spoon} alt="spoon" className="mx-auto w-12" />
        </div>

        <div className="relative max-w-7xl mx-auto">
          <div
            className="flex overflow-x-auto snap-x hide-scrollbar gap-4 pt-4 pb-12"
            ref={scrollRef}
          >
            {[
              images.home1,
              images.home2,
              images.home3,
              images.home4,
              images.home5,
              images.home6,
              images.home7,
              images.home8,
              images.home9,
            ].map((image, index) => (
              <div
                key={`gallery-image-${index}`}
                className="min-w-[280px] sm:min-w-[300px] h-[300px] sm:h-[400px] relative group snap-center"
              >
                <img
                  src={image}
                  alt={`gallery-${index}`}
                  className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
              </div>
            ))}
          </div>

          <button
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-primary-golden/80 p-2 rounded-full hover:bg-primary-golden transition-colors"
            onClick={() => scroll("left")}
          >
            <BsArrowLeftShort className="text-white text-2xl" />
          </button>

          <button
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-primary-golden/80 p-2 rounded-full hover:bg-primary-golden transition-colors"
            onClick={() => scroll("right")}
          >
            <BsArrowRightShort className="text-white text-2xl" />
          </button>
        </div>
      </div>

      {/* Values Section */}
      <div className="py-16 px-4 sm:px-8 lg:px-24">
        <div className="max-w-6xl mx-auto grid lg:grid-cols-3 gap-12">
          {[
            {
              title: "Craftsmanship",
              description:
                "Every dish is crafted with precision and passion, reflecting our commitment to culinary excellence.",
            },
            {
              title: "Hospitality",
              description:
                "We believe in creating moments of joy through genuine, warm, and attentive service.",
            },
            {
              title: "Innovation",
              description:
                "While respecting tradition, we continuously evolve our offerings to surprise and delight.",
            },
          ].map((value, index) => (
            <div key={index} className="text-center">
              <h3 className="font-cormorant text-primary-golden text-3xl mb-4">
                {value.title}
              </h3>
              <img
                src={images.spoon}
                alt="spoon"
                className="mx-auto w-12 mb-4"
              />
              <p className="p__opensans text-primary-gray">
                {value.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
