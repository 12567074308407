import React from "react";
import Header from "../Header/Header";
import SpecialMenu from "../Menu/SpecialMenu";
import Chef from "../Chef/Chef";
import Intro from "../Intro/Intro";
import Laurels from "../Laurels/Laurels";
import Gallery from "../Gallery/Gallery";
import FindUs from "../Findus/FindUs";

const Home = () => {
  return (
    <main>
      <Header />
      <Intro />
      <Laurels />
      <Gallery />
      <FindUs />
      {/* <SpecialMenu />
      <Chef /> */}
    </main>
  );
};

export default Home;
