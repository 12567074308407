import React, { useEffect } from "react";

const Map = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bg-[#0A0A0A] py-24 relative overflow-hidden">
      {/* Background decorative elements */}
      <div className="absolute inset-0 bg-gradient-radial from-[#1a1a1a] to-transparent opacity-20" />
      <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-[#B8860B] to-transparent opacity-30" />
      <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-[#B8860B] to-transparent opacity-30" />

      <div className="max-w-[1400px] mx-auto px-6 relative">
        {/* Section Header */}
        <div className="text-center mb-16">
          <h1 className="headtext__cormorant mb-4">Find Us</h1>
          <div className="h-0.5 w-24 bg-gradient-to-r from-transparent via-[#B8860B] to-transparent mx-auto" />
          <p className="text-gray-300 mt-6 max-w-2xl mx-auto text-lg">
            Experience luxury in the heart of the city. Visit us to discover an
            oasis of sophistication and culinary excellence.
          </p>
        </div>

        {/* Map Container */}
        <div className="relative">
          {/* Decorative corner elements */}
          <div className="absolute -top-4 -left-4 w-16 h-16 border-l-2 border-t-2 border-[#B8860B] opacity-50" />
          <div className="absolute -bottom-4 -right-4 w-16 h-16 border-r-2 border-b-2 border-[#B8860B] opacity-50" />

          {/* Map wrapper with golden border gradient */}
          <div className="relative p-1 bg-gradient-to-r from-[#B8860B] via-[#DAA520] to-[#B8860B]">
            <iframe
              title="location"
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1535.0806216959702!2d90.41533021961281!3d23.780963312542053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sbd!4v1731445721044!5m2!1sen!2sbd"
              className="w-full h-[600px] transition-transform duration-300 hover:scale-[1.01]"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>

        {/* Contact Information */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16 text-center">
          <div className="p-6 border border-[#B8860B]/20 backdrop-blur-sm">
            <h3 className="text-[#B8860B] text-xl mb-3">Address</h3>
            <p className="text-gray-300">
              Level-2, House-82, Road-17/A, Block-E,
              <br /> Banani Model Town, Bangladesh, 1213
            </p>
          </div>
          <div className="p-6 border border-[#B8860B]/20 backdrop-blur-sm">
            <h3 className="text-[#B8860B] text-xl mb-3">Hours</h3>
            <p className="text-gray-300">
              Mon - Sun: 5:00 PM - 2:00 AM
              <br />
              Happy Hour: 5:00 PM - 7:00 PM
            </p>
          </div>
          <div className="p-6 border border-[#B8860B]/20 backdrop-blur-sm">
            <h3 className="text-[#B8860B] text-xl mb-3">Contact</h3>
            <p className="text-gray-300">
              +08801622884949
              <br />
              contact@sloungerestaurant.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
