import React from "react";

import { Footer } from "./container";
import { Navbar } from "./components";
import "./App.css";
import { Outlet } from "react-router-dom";
import { ScrollToTopButton } from "./components/ScrollToTopOnMount/ScrollToTopOnMount";

const App = () => (
  <div>
    <ScrollToTopButton></ScrollToTopButton>
    <Navbar />
    <Outlet></Outlet>
    <Footer />
  </div>
);

export default App;
